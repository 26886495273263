.cp-textarea-container {
    margin-bottom: 25px;
}
.cp-textarea-wrapper {
    margin-bottom: 5px;
}
.cp-textarea {
    color: #3D2170;
    border: 2px solid #3D2170;
    border-radius: 7px;
}
.cp-textarea.form-control:focus {
    border-color: #3D2170;
    box-shadow: none;
    color: #3D2170;
}
.cp-textarea::-webkit-input-placeholder {
    color: #8572A5;
}
.cp-textarea:-moz-placeholder { /* Firefox 18- */
    color: #8572A5;
}
.cp-textarea::-moz-placeholder {  /* Firefox 19+ */
    color: #8572A5;
}
.cp-textarea:-ms-input-placeholder {
    color: #8572A5;
}
.cp-textarea::placeholder {
    color: #8572A5;
}
.cp-textarea-error {
    display: block;
    margin-bottom: -29px;
    color: #FF2B08;
}