.cp-timepicker-container {
    margin-bottom: 35px;
}
.cp-timepicker-wrapper {
    position: relative;
    border-radius: 0;
    border-bottom: 2px solid #3D2170;
    margin-bottom: 5px;
}
.cp-timepicker-field {
    padding-left: 0;
    padding-right: 20px;
    color: #3D2170;
    border: none;
    border-radius: 0;
}
.cp-timepicker-field.form-control:disabled {
    background: transparent;
}
.cp-timepicker-field.form-control:focus {
    border-color: transparent;
    box-shadow: none;
    color: #3D2170;
}
.cp-timepicker-field::-webkit-input-placeholder {
    color: #8572A5;
}
.cp-timepicker-field:-moz-placeholder { /* Firefox 18- */
    color: #8572A5;
}
.cp-timepicker-field::-moz-placeholder {  /* Firefox 19+ */
    color: #8572A5;
}
.cp-timepicker-field:-ms-input-placeholder {
    color: #8572A5;
}
.cp-timepicker-field::placeholder {
    color: #8572A5;
}
.cp-timepicker-error {
    display: block;
    margin-bottom: -29px;
    color: #FF2B08;
}

.cp-timepicker-wrapper .cp-timepicker-arrow {
    position: absolute;
    top: 5px;
    right: 0;
    width: 12px;
    height: 25px;
    cursor: pointer;
}
.cp-timepicker-wrapper .cp-timepicker-arrow:before,
.cp-timepicker-wrapper .cp-timepicker-arrow:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 4px;
    width: 7px;
    height: 2px;
    background: #3D2170;
}
.cp-timepicker-wrapper .cp-timepicker-arrow:before {
    right: 4px;
    transform: rotate(45deg);
}
.cp-timepicker-wrapper .cp-timepicker-arrow:after {
    right: 0;
    transform: rotate(-45deg);
}


.cp-timepicker-dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 10px;
    min-height: 50px;
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-sizing: border-box;
}
.cp-timepicker-dropdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cp-timepicker-dropdown .cp-timepicker-dropdown-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50px;
}
.cp-timepicker-dropdown-btn-up,
.cp-timepicker-dropdown-btn-down {
    position: relative;
    top: 0;
    right: 0;
    width: 12px;
    height: 25px;
    cursor: pointer;
}
.cp-timepicker-dropdown-btn-up:before,
.cp-timepicker-dropdown-btn-up:after,
.cp-timepicker-dropdown-btn-down:before,
.cp-timepicker-dropdown-btn-down:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 4px;
    width: 7px;
    height: 2px;
    background: #3D2170;
}
.cp-timepicker-dropdown-btn-up:before {
    right: 4px;
    transform: rotate(-45deg);
}
.cp-timepicker-dropdown-btn-up:after {
    right: 0;
    transform: rotate(45deg);
}
.cp-timepicker-dropdown-btn-down:before {
    right: 4px;
    transform: rotate(45deg);
}
.cp-timepicker-dropdown-btn-down:after {
    right: 0;
    transform: rotate(-45deg);
}
.cp-timepicker-dropdown .cp-timepicker-dropdown-controls .cp-timepicker-dropdown-value {
    padding: 6px;
    text-align: center;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 14px;
    flex: 1;
}
.cp-timepicker-period {
    background: #3D2170;
    color: #ffffff;
    padding: 5px 7px;
    border-radius: 10px;
    cursor: pointer;
}