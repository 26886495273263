.cp-qty-dropdown-container {
    margin-bottom: 35px;
}
.cp-qty-dropdown-wrapper {
    position: relative;
    border-radius: 0;
    border-bottom: 2px solid #3D2170;
    margin-bottom: 5px;
}
.cp-qty-dropdown-field {
    padding-left: 0;
    padding-right: 20px;
    color: #3D2170;
    border: none;
    border-radius: 0;
}
.cp-qty-dropdown-field.form-control:focus {
    border-color: transparent;
    box-shadow: none;
    color: #3D2170;
}
.cp-qty-dropdown-field::-webkit-input-placeholder {
    color: #8572A5;
}
.cp-qty-dropdown-field:-moz-placeholder { /* Firefox 18- */
    color: #8572A5;
}
.cp-qty-dropdown-field::-moz-placeholder {  /* Firefox 19+ */
    color: #8572A5;
}
.cp-qty-dropdown-field:-ms-input-placeholder {
    color: #8572A5;
}
.cp-qty-dropdown-field::placeholder {
    color: #8572A5;
}
.cp-qty-dropdown-error {
    display: block;
    margin-bottom: -29px;
    color: #FF2B08;
}

.cp-qty-dropdown-wrapper .cp-qty-dropdown-arrow {
    position: absolute;
    top: 5px;
    right: 0;
    width: 12px;
    height: 25px;
    cursor: pointer;
}
.cp-qty-dropdown-wrapper .cp-qty-dropdown-arrow:before,
.cp-qty-dropdown-wrapper .cp-qty-dropdown-arrow:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 4px;
    width: 7px;
    height: 2px;
    background: #3D2170;
}
.cp-qty-dropdown-wrapper .cp-qty-dropdown-arrow:before {
    right: 4px;
    transform: rotate(45deg);
}
.cp-qty-dropdown-wrapper .cp-qty-dropdown-arrow:after {
    right: 0;
    transform: rotate(-45deg);
}
.cp-qty-dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 10px;
    min-height: 50px;
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-sizing: border-box;
}
.cp-qty-dropdown .cp-qty-dropdown-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cp-qty-dropdown .cp-qty-dropdown-controls .cp-qty-dropdown-btn {
    background: #3D2170;
    color: #ffffff;
    padding: 5px;
    font-size: 28px;
    line-height: 16px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 10px;
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;
}
.cp-qty-dropdown .cp-qty-dropdown-controls .cp-qty-dropdown-btn.plus {
    padding: 5px 4px 4px 5px;
}
.cp-qty-dropdown .cp-qty-dropdown-controls .cp-qty-dropdown-btn.minus {
    padding: 6px 8px;
}
.cp-qty-dropdown .cp-qty-dropdown-controls .cp-qty-dropdown-btn.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.cp-qty-dropdown .cp-qty-dropdown-controls .cp-qty-dropdown-value {
    padding: 6px;
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 14px;
    flex: 1;
}