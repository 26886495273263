.testimonial-container {
    position: relative;
    max-width: 318px;
    min-width: 318px;
    height: 300px;
    margin: 0 auto;
}
.testimonial-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 55px;
    bottom: 40px;
    z-index: 5;
    padding: 20px 20px 60px 20px;
    color: #ffffff;
    font-size: 19px;
    line-height: 19px;
    border: 5px solid #ffffff;
    border-radius: 40px;
}
.testimonial-container .circle-1,
.testimonial-container .circle-2,
.testimonial-container .circle-3 {
    position: absolute;
    right: 55px;
    bottom: 40px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: 5px solid #ffffff;
    z-index: 5;
}
.testimonial-container .circle-1 {
    position: absolute;
    right: 40px;
    bottom: 12px;
    width: 100px;
    height: 100px;
    z-index: 6;
}
.testimonial-container .circle-2 {
    position: absolute;
    right: 0px;
    bottom: 30px;
    width: 120px;
    height: 120px;
    border-radius: 70px;
    z-index: 3;
}
.testimonial-container .circle-3 {
    position: absolute;
    right: 5px;
    bottom: 0px;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    z-index: 4;
}
.testimonial-container .testimonial-wrapper.p-o-b ~ .circle-1 {
    background: #FF4612;
}
.testimonial-container .testimonial-wrapper.o-b-p ~ .circle-1 {
    background: #049ADA;
}
.testimonial-container .testimonial-wrapper.b-p-o ~ .circle-1 {
    background: #6E35AE;
}
.testimonial-container .testimonial-wrapper.p-o-b ~ .circle-2 {
    background: #049ADA;
}
.testimonial-container .testimonial-wrapper.o-b-p ~ .circle-2 {
    background: #6E35AE;
}
.testimonial-container .testimonial-wrapper.b-p-o ~ .circle-2 {
    background: #FF4612;
}
.testimonial-container .testimonial-wrapper.p-o-b ~ .circle-3 {
    background: #6E35AE;
}
.testimonial-container .testimonial-wrapper.o-b-p ~ .circle-3 {
    background: #FF4612;
}
.testimonial-container .testimonial-wrapper.b-p-o ~ .circle-3 {
    background: #049ADA;
}
.testimonial-wrapper.p-o-b {
    background: #6E35AE;
}
.testimonial-wrapper.o-b-p {
    background: #FF4612;
}
.testimonial-wrapper.b-p-o {
    background: #049ADA;
}
@media (max-width: 1199px) {
    .testimonial-container {
        max-width: 300px;
        min-width: 300px;
    }
}