/* logo rotate animation */
.logo-image {
    display: flex;
    justify-content: flex-end;
}
.logo-image .logo-image-wrapper {
    position: relative;
    height: 400px;
    width: 400px;
}
.logo-image .logo-image-wrapper img {
    position: absolute;
    top: -12px;
}
.logo-image .logo-image-wrapper img.logo {
    opacity: 0;
    z-index: 10;
}
.logo-image .logo-image-wrapper.firstAnimate {
    opacity: 0;
    -webkit-animation: logo-first-rotate 6.5s; /* Safari 4+ */
    animation:         logo-first-rotate 6.5s;
    animation-timing-function: ease-out;
}
.logo-image .logo-image-wrapper img.logo.firstAnimate {
    -webkit-animation: logo-first-show 6.5s; /* Safari 4+ */
    animation:         logo-first-show 6.5s;
    animation-timing-function: linear;
}
@-webkit-keyframes logo-first-rotate {
    0%   { transform: rotate(0deg); opacity: 0; }
    20%   { transform: rotate(0deg); opacity: 1; }
    33%   { transform: rotate(360deg); opacity: 1; }
    100%   { transform: rotate(360deg); opacity: 1; }
}
@keyframes logo-first-rotate {
    0%   { transform: rotate(0deg); opacity: 0; }
    20%   { transform: rotate(0deg); opacity: 1; }
    33%   { transform: rotate(360deg); opacity: 1; }
    100%   { transform: rotate(360deg); opacity: 1; }
}

@-webkit-keyframes logo-first-show {
    0%   { opacity: 0; }
    20%   { opacity: 0; }
    40% { opacity: 1; }
    95% { opacity: 1; }
    100% { opacity: 0; }
}
@keyframes logo-first-show {
    0%   { opacity: 0; }
    20%   { opacity: 0; }
    40% { opacity: 1; }
    95% { opacity: 1; }
    100% { opacity: 0; }
}

.logo-image .logo-image-wrapper.animate {
    -webkit-animation: logo-rotate 6.5s; /* Safari 4+ */
    animation:         logo-rotate 6.5s;
    animation-timing-function: ease-out;
}
.logo-image .logo-image-wrapper img.logo.animate {
    -webkit-animation: logo-show 6.5s; /* Safari 4+ */
    animation:         logo-show 6.5s;
    animation-timing-function: linear;
}
@-webkit-keyframes logo-rotate {
    0%   { transform: rotate(0deg); }
    7%   { transform: rotate(0deg); }
    23%   { transform: rotate(360deg); }
    100%   { transform: rotate(360deg); }
}
@keyframes logo-rotate {
    0%   { transform: rotate(0deg); }
    7%   { transform: rotate(0deg); }
    23%   { transform: rotate(360deg); }
    100%   { transform: rotate(360deg); }
}
@-webkit-keyframes logo-show {
    0%   { opacity: 0; }
    7%   { opacity: 0; }
    40% { opacity: 1; }
    95% { opacity: 1; }
    100% { opacity: 0; }
}
@keyframes logo-show {
    0%   { opacity: 0; }
    7%   { opacity: 0; }
    40% { opacity: 1; }
    95% { opacity: 1; }
    100% { opacity: 0; }
}
/* logo rotate animation end */
@media (max-width: 991px) {
    .home-logo-section .logo-image {
        display: none;
    }
}