.contact-us-section {
    padding-top: 100px;
    padding-bottom: 60px;
}
.contact-us-section .contact-us-form {
    margin-bottom: 50px;
}
.contact-us-section .cp-btn,
.contact-us-section .cp-btn-link {
    margin-bottom: 20px;
}
.contact-us-btns-container .cp-btn {
    min-width: 230px;
}
.contact-us-section .contact-us-image-container {
    margin-top: 110px;
}

@media (max-width: 767px) {
    .contact-us-section .contact-us-image-container {
        display: none;
    }
}