.cp-input-container {
    margin-bottom: 35px;
}
.cp-input-wrapper {
    border-radius: 0;
    border-bottom: 2px solid #3D2170;
    margin-bottom: 5px;
}
.cp-input {
    padding-left: 0;
    color: #3D2170;
    border: none;
    border-radius: 0;
}
.cp-input.form-control:focus {
    border-color: transparent;
    box-shadow: none;
    color: #3D2170;
}
.cp-input::-webkit-input-placeholder {
    color: #8572A5;
}
.cp-input:-moz-placeholder { /* Firefox 18- */
    color: #8572A5;
}
.cp-input::-moz-placeholder {  /* Firefox 19+ */
    color: #8572A5;
}
.cp-input:-ms-input-placeholder {
    color: #8572A5;
}
.cp-input::placeholder {
    color: #8572A5;
}
.cp-input-error {
    display: block;
    margin-bottom: -29px;
    color: #FF2B08;
}