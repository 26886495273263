.cp-select-container {
    margin-bottom: 35px;
}
.cp-select-wrapper {
    position: relative;
    border-radius: 0;
    border-bottom: 2px solid #3D2170;
    margin-bottom: 5px;
}
.cp-select-wrapper>div:focus {
    outline: none !important;
}
.cp-select-container .cp-select__control {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
}
.cp-select-container .cp-select__value-container {
    padding-left: 0;
}
.cp-select-container .cp-select__placeholder {
    color: #8572A5;
    margin: 0;
}
.cp-select-container .cp-select__single-value {
    color: #3D2170;
    margin: 0;
}
.cp-select-container .cp-select__indicator,
.cp-select-container .cp-select__indicator:hover {
    color: #3D2170;
    cursor: pointer;
}
.cp-select-container .cp-select__indicators>span {
    display: none;
}
.cp-select-container .cp-select__menu-list {
    padding: 5px;
}
.cp-select-container .cp-select__option {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
}
.cp-select-container .cp-select__option:hover {
    background: #eeeeee;
}
.cp-select-container .cp-select__option.cp-select__option--is-selected {
    background: #3D2170;
    cursor: initial;
}
.cp-select-error {
    display: block;
    margin-bottom: -29px;
    color: #FF2B08;
}
