.cp-btn {
    font-size: 1rem;
    font-weight: 300;
    line-height: 23px;
    color: #FF4612;
    background: #ffffff;
    border: 2px solid #FF4612;
    border-radius: 30px;
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    padding: .5rem 1rem;
    padding-left: 25px;
    padding-right: 25px;
}
.cp-btn:hover,
.cp-btn:focus,
.cp-btn.active,
.cp-btn-fill {
    background: #FF4612;
    color: #ffffff;
}
.cp-btn:disabled {
    background: #cccccc !important;
    border-color: #cccccc !important;
    cursor: default !important;
}