.privacy-section {
    padding-top: 100px;
    padding-bottom: 60px;
}
.privacy-section h1 {
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 10px;
}
.privacy-section .privacy-version {
    font-size: 20px;
}
.privacy-section .privacy-download-container {
    margin-top: 30px;
    text-decoration: none;
}
.privacy-section .privacy-download-container:hover,
.privacy-section .privacy-download-container:focus {
    outline: none;
    text-decoration: none;
}
.privacy-section .privacy-download-container h2 {
    font-size: 27px;
    margin-bottom: 10px;
}
.privacy-section .privacy-download-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.privacy-section .privacy-download-wrapper label {
    cursor: pointer;
    font-size: 20px;
    margin: 0;
}
.privacy-section .privacy-download-wrapper img {
    width: 80px;
    margin-right: 30px;
}

@media (max-width: 1199px) {
    .privacy-section h1 {
        font-size: 32px;
    }
}
@media (max-width: 991px) {
    .privacy-section h1 {
        font-size: 27px;
    }
}
@media (max-width: 767px) {
    .privacy-section .privacy-image-container {
        margin-top: 100px;
        text-align: center;
    }
}