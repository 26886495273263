/* home page */
.home-banner-section {
    padding-top: 100px;
    padding-bottom: 100px;
}
.home-banner-section .banner-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.home-banner-section .banner-title h1 {
    font-size: 80px;
}
.home-banner-section .banner-title p {
    font-size: 33px;
    line-height: 43px;
    font-weight: 400;
    color: #1F1D1D;
    margin: 0;
}
.home-banner-section .banner-btns {
    margin-top: 40px;
}
.home-banner-section .banner-btns .cp-btn-link {
    min-width: 160px;
    font-size: 18px;
}
.home-banner-section .banner-btns .cp-btn-link:nth-child(1) {
    margin-right: 20px;
}

.home-dismissal-section {
    margin-bottom: 40px;
}
.home-dismissal-section h2 {
    font-size: 48px;
}
.home-dismissal-section .afternoon-video-container {
    position: relative;
    margin: 20px;
    padding: 5%;
}
.home-dismissal-section .afternoon-video-container .video-left-circle-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    height: 170px;
    z-index: -2;
}
.home-dismissal-section .afternoon-video-container .video-left-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    height: 170px;
    border-radius: 85px;
    border: 4px solid #ffffff;
    background: #FF2B08;
    z-index: -2;
}
.home-dismissal-section .afternoon-video-container .video-left-circle:before {
    content: "";
    position: absolute;
    top: 10px;
    left: -15px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 4px solid #ffffff;
    background: #6E35AE;
    z-index: -1;
}
.home-dismissal-section .afternoon-video-container .video-right-circle-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 170px;
    height: 170px;
    z-index: -2;
}
.home-dismissal-section .afternoon-video-container .video-right-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    height: 170px;
}
.home-dismissal-section .afternoon-video-container .video-right-circle .child {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    height: 170px;
    border-radius: 85px;
    border: 4px solid #ffffff;
    background: #049ADA;
    z-index: -2;
}
.home-dismissal-section .afternoon-video-container .video-right-circle:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: -15px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: 4px solid #ffffff;
    background: #FF2B08;
    z-index: -1;
}
.home-dismissal-section .afternoon-video-container .video-right-circle:after {
    content: "";
    position: absolute;
    bottom: 32px;
    right: -40px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    border: 4px solid #ffffff;
    background: #6E35AE;
    z-index: -3;
}
.home-dismissal-section .afternoon-video-container .afternoon-video {
    background: #ffffff;
    border: 10px solid #ffffff;
    border-radius: 23px;
}
.home-dismissal-section .afternoon-video-container .afternoon-video video {
    display: block;
    width: 100%;
    border-radius: 10px;
}
.home-dismissal-section .afternoon-video-container .afternoon-video video:focus {
    outline: none;
}

.home-features-section {
    margin-bottom: 40px;
}
.home-features-section h2 {
    font-size: 48px;
}
.home-features-section .feature-container {
    padding: 20px;
}
.home-features-section .feature-container .feature-image {
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
}
.home-features-section .feature-container .feature-image.efficient {
    background-image: url(../../assets/images/efficient.png);
}
.home-features-section .feature-container .feature-image.effective {
    background-image: url(../../assets/images/effective.png);
}
.home-features-section .feature-container .feature-image.timely {
    background-image: url(../../assets/images/timely.png);
}
.home-features-section .feature-container h3 {
    font-size: 40px;
}
.home-features-section .feature-container p {
    font-size: 22px;
    line-height: 30px;
    margin: 0;
}

.home-ads-section {
    margin-bottom: 30px;
}
    .home-ads-section h2 {
        font-size: 48px;
        margin-bottom: 30px;
    }
.home-ads-section .flip-container {
    overflow: hidden;
}
.home-ads-section .ads-container {
    font-size: 25px;
    line-height: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 30px;
    text-align: center;
}
.home-ads-section .ads-container .big {
    font-size: 50px;
}
.home-ads-section .ads-container .img-text {
    display: none;
}
.home-ads-section .ads-container img {
    margin: 0 20px;
}

@media (max-width: 1201px) {
    .home-ads-section .ads-container {
        font-size: 25px;
        line-height: 40px;
    }
    .home-ads-section .ads-container .big {
        font-size: 35px;
    }
}
@media (max-width: 991px) {
    .home-banner-section .banner-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .home-banner-section .banner-title h1 {
        font-size: 48px;
    }
    .home-banner-section .banner-title p {
        font-size: 25px;
        line-height: 35px;
    }
    .home-banner-section .banner-btns {
        max-width: 210px;
    }
    .home-banner-section .banner-btns .cp-btn-link:nth-child(1) {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .home-banner-section .logo-image-wrapper {
        display: none;
    }
    .home-features-section h2 {
        font-size: 33px;
        text-align: center;
    }
    .home-ads-section h2 {
        font-size: 33px;
        text-align: center;
    }
    .home-ads-section .ads-container {
        display: block;
    }
    .home-ads-section .ads-container .img-text {
        display: inline-block;
    }
    .home-ads-section .ads-container img {
        display: none;
    }

    .home-features-section .feature-container h3 {
        font-size: 30px;
    }
    .home-features-section .feature-container p {
        font-size: 20px;
        line-height: 25px;
    }

    .home-dismissal-section h2 {
        font-size: 33px;
        text-align: center;
    }
    .home-dismissal-section .afternoon-video-container {
        position: relative;
        margin: 20px;
        padding:0;
    }
    .home-dismissal-section .afternoon-video-container .video-left-circle-wrapper {
        width: 110px;
        height: 110px;
        top: -35px;
        left: -45px;
    }
    .home-dismissal-section .afternoon-video-container .video-left-circle {
        width: 110px;
        height: 110px;
    }
    .home-dismissal-section .afternoon-video-container .video-left-circle:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -10px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 3px solid #ffffff;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle-wrapper {
        bottom: -50px;
        right: -30px;
        width: 110px;
        height: 110px;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle {
        width: 110px;
        height: 110px;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle .child {
        width: 110px;
        height: 110px;
        border-radius: 55px;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle:before {
        bottom: 0;
        right: -8px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 3px solid #ffffff;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle:after {
        bottom: 20px;
        right: -30px;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 3px solid #ffffff;
    }
}
@media (max-width: 576px) {
    .home-banner-section .banner-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .home-banner-section .banner-btns {
        margin: 30px auto 0;
    }
    .home-dismissal-section .afternoon-video-container .video-left-circle-wrapper {
        width: 80px;
        height: 80px;
        top: -25px;
        left: -25px;
    }
    .home-dismissal-section .afternoon-video-container .video-left-circle {
        width: 80px;
        height: 80px;
    }
    .home-dismissal-section .afternoon-video-container .video-left-circle:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -10px;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 3px solid #ffffff;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle-wrapper {
        bottom: -35px;
        right: -25px;
        width: 80px;
        height: 80px;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle {
        width: 80px;
        height: 80px;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle .child {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 3px solid #ffffff;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle:before {
        bottom: -5px;
        right: 0px;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 3px solid #ffffff;
    }
    .home-dismissal-section .afternoon-video-container .video-right-circle:after {
        bottom: 4px;
        right: -10px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        border: 3px solid #ffffff;
    }
}
@media (max-width: 460px) {
    .home-dismissal-section h2 {
        font-size: 20px;
        text-align: center;
    }
    .home-dismissal-section .afternoon-video-container .afternoon-video {
        border: 5px solid #F8F8F8;
        border-radius: 10px;
    }
}
/* home page end */

/* video custom animation */
@-webkit-keyframes videoBounceInLeft {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-150px, 0, 0);
        transform: translate3d(-150px, 0, 0);
    }

    45% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }

    65% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    85% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes videoBounceInLeft {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-150px, 0, 0);
        transform: translate3d(-150px, 0, 0);
    }

    45% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }

    65% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    85% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
    }
}

.videoBounceInLeft {
    -webkit-animation-name: videoBounceInLeft;
    animation-name: videoBounceInLeft;
}

@-webkit-keyframes bounceInRight {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(150px, 0, 0);
        transform: translate3d(150px, 0, 0);
    }

    45% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }

    65% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    85% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes bounceInRight {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(150px, 0, 0);
        transform: translate3d(150px, 0, 0);
    }

    45% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }

    65% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    85% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    45% {
        -webkit-transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 1deg);
        transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 1deg);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    45% {
        -webkit-transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 1deg);
        transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 1deg);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.animate__wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
}
/* video custom animation end */