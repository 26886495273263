/* about us page */
.about-better-solution-section {
    position: relative;
    padding-top: 100px;
    padding-bottom: 40px;
    background-image: url(../../assets/images/cp-about.png);
    background-repeat: no-repeat;
    background-position: top -2px right 0px;
    background-size: 479px 424px;
}
.about-better-solution-section .border-colored {
    position: absolute;
    top: 62px;
    right: -120px;
    width: 600px;
    height: 300px;
    background: #049ADA;
    z-index: -1;
    border-radius: 300px;
    transform: rotate(-49deg);
}
.about-better-solution-section .border-colored:before {
    content: "";
    position: absolute;
    top: -46px;
    right: 0px;
    width: 600px;
    height: 300px;
    background: #FF2B08;
    z-index: -1;
    border-radius: 300px;
}
.about-better-solution-section .border-colored:after {
    content: "";
    position: absolute;
    top: -23px;
    right: 0px;
    width: 600px;
    height: 300px;
    background: #6E35AE;
    z-index: -2;
    border-radius: 300px;
}
.about-better-solution-section h1 {
    font-size: 48px;
}
.about-better-solution-section .description {
    font-size: 30px;
    line-height: 30px;
    margin: 0;
}

.about-features-section {
    margin-bottom: 50px;
}
.about-features-section .feature-container {
    padding: 20px;
}
.about-features-section .feature-container .feature-image {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-features-section .feature-container .feature-image .dismissal,
.about-features-section .feature-container .feature-image .process,
.about-features-section .feature-container .feature-image .swift {
    position: relative;
    width: 170px;
    height: 170px;
    border-radius: 85px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 101%;
}
.about-features-section .feature-container .feature-image .dismissal:before,
.about-features-section .feature-container .feature-image .process:before,
.about-features-section .feature-container .feature-image .swift:before {
    width: 170px;
    height: 170px;
    position: absolute;
    top: -4px;
    left: 2px;
    border-radius: 85px;
    z-index: -1;
}
.about-features-section .feature-container .feature-image .dismissal {
    border: 7px solid #6E35AE;
    background: #6E35AE url(../../assets/images/cp-dismissal.png) center center no-repeat;
    background-size: cover;
}
.about-features-section .feature-container .feature-image .dismissal:before {
    content: "";
    background: #049ADA;
}
.about-features-section .feature-container .feature-image .process {
    border: 7px solid #049ADA;
    background: #049ADA url(../../assets/images/cp-process.png) center center no-repeat;
    background-size: cover;
}
.about-features-section .feature-container .feature-image .process:before {
    content: "";
    background: #FF2B08;
}
.about-features-section .feature-container .feature-image .swift {
    border: 7px solid #FF2B08;
    background: #FF2B08 url(../../assets/images/cp-swift.png) center center no-repeat;
    background-size: cover;
}
.about-features-section .feature-container .feature-image .swift:before {
    content: "";
    background: #6E35AE;
}
.about-features-section .feature-container h3 {
    font-size: 40px;
}
.about-features-section .feature-container p {
    font-size: 22px;
    line-height: 30px;
    margin: 0;
}

.about-testimonials-section {
    margin-bottom: 70px;
}
.about-testimonials-section h2 {
    font-size: 48px;
    margin-bottom: 30px;
}
.about-testimonials-section .about-testimonials-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (max-width: 1199px) {
    .about-testimonials-section .about-testimonials-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}
@media (max-width: 1170px) {

    .about-better-solution-section {
        padding-top: 70px;
        background-image: url(../../assets/images/cp-about.png);
        background-repeat: no-repeat;
        background-position: top -2px right -60px;
        background-size: 479px 424px;
    }
    .about-better-solution-section .border-colored {
        position: absolute;
        top: 62px;
        right: -180px;
    }
    .about-better-solution-section .description {
        font-size: 25px;
        line-height: 25px;
    }
}
@media (max-width: 991px) {
    .about-better-solution-section {
        background: none;
    }
    .about-better-solution-section .border-colored {
        display: none;
    }
    .about-better-solution-section h1 {
        font-size: 33px;
        text-align: center;
    }
    .about-better-solution-section .description {
        font-size: 23px;
    }

    .about-features-section .feature-container h3 {
        font-size: 30px;
    }
    .about-features-section .feature-container p {
        font-size: 20px;
        line-height: 25px;
    }

    .about-testimonials-section h1 {
        font-size: 33px;
    }
}
@media (max-width: 460px) {
    .about-better-solution-section h1 {
        font-size: 30px;
        text-align: center;
    }
    .about-better-solution-section .description {
        font-size: 20px;
    }

    .about-testimonials-section h1 {
        font-size: 30px;
    }
}
@media (min-width: 992px) {
    .about-features-section>.container {
        padding-right: 80px;
    }
}
/* about us page end */