.requests-list-section {
    padding: 30px 50px 50px;
}
.rl-table-container {

}
.rl-tabs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -2px;
    min-height: 47px;
}
.rl-tabs {
    display: flex;
}
.rl-tabs .rl-tab-item {
    position: relative;
    color: #ffffff;
    font-size: 20px;
    background: #6E35AE;
    border: 2px solid #f7f7f7;
    border-top-right-radius: 10px;
    padding: 5px 20px 8px;
    margin-left: -7px;
    width: 150px;
    text-align: center;
    cursor: pointer;
}
.rl-tabs .rl-tab-item:nth-child(1) {
    border-top-left-radius: 10px;
    margin-left: 0;
    z-index: 4;
}
.rl-tabs .rl-tab-item:nth-child(2) {
    z-index: 3;
}
.rl-tabs .rl-tab-item:nth-child(3) {
    z-index: 2;
}
.rl-tabs .rl-tab-item:nth-child(4) {
    z-index: 1;
}
.rl-tabs .rl-tab-item.active {
    background: #FF2B08;
}
.rl-tabs .rl-tab-item span {
    position: absolute;
    top: -14px;
    right: -14px;
    background: #16B91D;
    width: 28px;
    height: 28px;
    border: 1px solid #ffffff;
    border-radius: 20px;
    font-size: 20px;
    line-height: 28px;
    display: block;
}
.rl-tabs-btns {
    margin-top: -20px;
    display: flex;
    align-items: center;
}
.rl-tabs-filter-container {
    margin: 10px;
}
.rl-tabs-filter-container .react-datepicker-popper {
    z-index: 100;
}
.rl-tabs-filter-container .react-datepicker-wrapper {
    position: relative;
    cursor: pointer;
}
.rl-tabs-filter-container .react-datepicker-wrapper:before {
    content: "";
    position: absolute;
    top: 7px;
    right: 10px;
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #626065;
    border-right: 2px solid #626065;
    transform: rotate(45deg);
    z-index: -1;
}
.rl-tabs-filter-container .react-datepicker-wrapper input {
    border: none;
    color: #626065;
    cursor: pointer;
    width: 130px;
    background: transparent;
    font-weight: 600;
}
.rl-tabs-filter-container .react-datepicker__close-icon {
    right: 17px;
}
.rl-tabs-filter-container .react-datepicker__close-icon:after {
    background-color: #FF4612;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    padding: 0 0 0 1px;
}
.rl-tabs-btns .tab-btn {
    padding: 5px 20px;
    margin: 0 5px;
}
.rl-table-wrapper {
    border: 2px solid #6E35AE;
    min-height: 100px;
    border-radius: 8px;
    margin-top: -8px;
    position: relative;
    z-index: 10;
    background: #f7f7f7;
    overflow: auto;
}
.rl-table-wrapper.empty {
    border: 2px solid transparent;
    border-radius: 0;
}
.rl-table-wrapper .empty-list {
    color: #605774;
    height: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.rl-table-wrapper .empty-list .empty-list-img-container {
    margin-bottom: 20px;
    width: 250px;
    height: 250px;
    border-radius: 125px;
    background: #EFEFF0;
}
.rl-table-wrapper .empty-list .empty-list-img {
    margin-bottom: 20px;
    width: 250px;
    height: 250px;
    border-radius: 125px;
    background: url(../../assets/images/records-empty.svg);
    background-repeat: no-repeat;
    background-size: 139px 150px;
    background-position: center;
}
.rl-table-wrapper .table th {
    color: #626065;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 2px solid #6E35AE;
}
.rl-table-wrapper .table td, .rl-table-wrapper .table th {
    border-top: none;
    color: #626065;
}
.rl-table-wrapper .table td {
    height: 50px;
    vertical-align: middle;
}
.rl-table-wrapper .table tr.contacted {
    background: rgba(110, 53, 174, 0.1);
}
.rl-table-wrapper .table tr.new {
    font-weight: 600;
}
.rl-table-wrapper .table tr.hide {
    display: none;
}
.rl-no, .rl-name, .rl-date, .rl-email, .rl-campus-role, .rl-steps, .rl-results,
.rl-school-name, .rl-school-address, .rl-school-district, .rl-best-contact-time,
.rl-phone, .rl-comment, .rl-students-qty, .rl-faculty-qty {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rl-no {
    max-width: 80px;
    min-width: 80px;
}
.rl-name {
    max-width: 200px;
    min-width: 200px;
}
.rl-date {
    max-width: 145px;
    min-width: 145px;
}
.rl-email {
    max-width: 200px;
    min-width: 200px;
}
.rl-campus-role {
    max-width: 160px;
    min-width: 160px;
}
.rl-steps {
    max-width: 150px;
    min-width: 150px;
}
.rl-results {
    max-width: 155px;
    min-width: 155px;
}
.rl-school-name {
    max-width: 150px;
    min-width: 150px;
}
.rl-school-address {
    max-width: 180px;
    min-width: 180px;
}
.rl-school-district {
    max-width: 160px;
    min-width: 160px;
}
.rl-best-contact-time {
    max-width: 190px;
    min-width: 190px;
}
.rl-phone {
    max-width: 100px;
    min-width: 100px;
}
.rl-comment {
    max-width: 200px;
    min-width: 200px;
}
.rl-students-qty {
    max-width: 210px;
    min-width: 210px;
}
.rl-faculty-qty {
    max-width: 245px;
    min-width: 245px;
}

/* Hide the browser's default checkbox */
.cp-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* cp tooltip */
.cp-tooltip {
    max-width: 400px !important;
    white-space: initial !important;
    background: rgba(110, 53, 174, 0.7) !important;
    color: #ffffff !important;
}
.cp-tooltip.place-left:after {
    border-left-color: rgba(110, 53, 174, 0.7) !important;
}
.cp-tooltip.place-right:after {
    border-right-color: rgba(110, 53, 174, 0.7) !important;
}
.cp-tooltip.place-top:after {
    border-top-color: rgba(110, 53, 174, 0.7) !important;
}
.cp-tooltip.place-bottom:after {
    border-bottom-color: rgba(110, 53, 174, 0.7) !important;
}
/* cp tooltip end */

/* Create a custom checkbox */
.cp-checkbox-checkmark {
    position: absolute;
    top: -6px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #6E35AE;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.cp-checkbox-container:hover input ~ .cp-checkbox-checkmark {
    background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.cp-checkbox-container input:checked ~ .cp-checkbox-checkmark {
    background-color: #6E35AE;
}

/* Create the checkmark/indicator (hidden when not checked) */
.cp-checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.cp-checkbox-container input:checked ~ .cp-checkbox-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.cp-checkbox-container .cp-checkbox-checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
    .cp-pagination-info {
        display: none;
    }
    .cp-checkbox-checkmark {
        top: 0;
    }
    .rl-table-wrapper .table {
        margin: 0;
    }
    /*/!* Force table to not be like tables anymore *!/*/
    /*.rl-table-wrapper .table,*/
    /*.rl-table-wrapper .table thead,*/
    /*.rl-table-wrapper .table tbody,*/
    /*.rl-table-wrapper .table th,*/
    /*.rl-table-wrapper .table td,*/
    /*.rl-table-wrapper .table tr {*/
        /*display: block;*/
    /*}*/

    /*/!* Hide table headers (but not display: none;, for accessibility) *!/*/
    /*.rl-table-wrapper .table thead tr {*/
        /*position: absolute;*/
        /*top: -9999px;*/
        /*left: -9999px;*/
    /*}*/

    /*.rl-table-wrapper .table tr {*/
        /*border: 1px solid #6E35AE;*/
    /*}*/

    /*.rl-table-wrapper .table td {*/
        /*/!* Behave  like a "row" *!/*/
        /*border: none;*/
        /*border-bottom: 1px solid #6E35AE;*/
        /*position: relative;*/
        /*padding-left: 50%;*/
        /*max-width: 100%;*/
        /*min-height: 50px;*/
        /*height: 50px;*/
        /*vertical-align: top;*/
    /*}*/

    /*.rl-table-wrapper .table td:before {*/
        /*/!* Now like a table header *!/*/
        /*position: absolute;*/
        /*/!* Top/left values mimic padding *!/*/
        /*top: 12px;*/
        /*left: 6px;*/
        /*width: 45%;*/
        /*font-weight: 700;*/
        /*padding-right: 10px;*/
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
    /*}*/

    /*/!* Support table Label the data *!/*/
    /*.table-support td:nth-of-type(1):before { content: ""; }*/
    /*.table-support td:nth-of-type(2):before { content: "No."; }*/
    /*.table-support td:nth-of-type(3):before { content: "Name"; }*/
    /*.table-support td:nth-of-type(4):before { content: "Email"; }*/
    /*.table-support td:nth-of-type(5):before { content: "Campus Role"; }*/
    /*.table-support td:nth-of-type(6):before { content: "What Steps"; }*/
    /*.table-support td:nth-of-type(7):before { content: "What happens"; }*/
    /*.table-support td:nth-of-type(8):before { content: "Contact Date"; }*/
    /*.table-support td:nth-of-type(9):before { content: "Date"; }*/

    /*/!* Demo table Label the data *!/*/
    /*.table-demo td:nth-of-type(1):before { content: ""; }*/
    /*.table-demo td:nth-of-type(2):before { content: "No."; }*/
    /*.table-demo td:nth-of-type(3):before { content: "Name"; }*/
    /*.table-demo td:nth-of-type(4):before { content: "Email"; }*/
    /*.table-demo td:nth-of-type(5):before { content: "Campus Role"; }*/
    /*.table-demo td:nth-of-type(6):before { content: "School Name"; }*/
    /*.table-demo td:nth-of-type(7):before { content: "School Address"; }*/
    /*.table-demo td:nth-of-type(8):before { content: "School District"; }*/
    /*.table-demo td:nth-of-type(9):before { content: "Best Contact Time"; }*/
    /*.table-demo td:nth-of-type(10):before { content: "Phone"; }*/
    /*.table-demo td:nth-of-type(11):before { content: "Comment"; }*/
    /*.table-demo td:nth-of-type(12):before { content: "Contact Date"; }*/
    /*.table-demo td:nth-of-type(13):before { content: "Date"; }*/

    /*/!* Quote table Label the data *!/*/
    /*.table-quote td:nth-of-type(1):before { content: ""; }*/
    /*.table-quote td:nth-of-type(2):before { content: "No."; }*/
    /*.table-quote td:nth-of-type(3):before { content: "Name"; }*/
    /*.table-quote td:nth-of-type(4):before { content: "Email"; }*/
    /*.table-quote td:nth-of-type(5):before { content: "Campus Role"; }*/
    /*.table-quote td:nth-of-type(6):before { content: "School Name"; }*/
    /*.table-quote td:nth-of-type(7):before { content: "School Address"; }*/
    /*.table-quote td:nth-of-type(8):before { content: "School District"; }*/
    /*.table-quote td:nth-of-type(9):before { content: "Number of Students"; }*/
    /*.table-quote td:nth-of-type(10):before { content: "Number of Faculty/Staff"; }*/
    /*.table-quote td:nth-of-type(11):before { content: "Phone"; }*/
    /*.table-quote td:nth-of-type(12):before { content: "Comment"; }*/
    /*.table-quote td:nth-of-type(13):before { content: "Contact Date"; }*/
    /*.table-quote td:nth-of-type(14):before { content: "Date"; }*/

    /*/!* Contact table Label the data *!/*/
    /*.table-contact td:nth-of-type(1):before { content: ""; }*/
    /*.table-contact td:nth-of-type(2):before { content: "No."; }*/
    /*.table-contact td:nth-of-type(3):before { content: "Name"; }*/
    /*.table-contact td:nth-of-type(4):before { content: "Email"; }*/
    /*.table-contact td:nth-of-type(5):before { content: "Campus Role"; }*/
    /*.table-contact td:nth-of-type(6):before { content: "School Name"; }*/
    /*.table-contact td:nth-of-type(7):before { content: "School Address"; }*/
    /*.table-contact td:nth-of-type(8):before { content: "School District"; }*/
    /*.table-contact td:nth-of-type(9):before { content: "Phone"; }*/
    /*.table-contact td:nth-of-type(10):before { content: "Comment"; }*/
    /*.table-contact td:nth-of-type(11):before { content: "Contact Date"; }*/
    /*.table-contact td:nth-of-type(12):before { content: "Date"; }*/
}

.rl-bottom-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.rl-bottom-panel .rl-bottom-btn {
    padding: 5px 20px;
}


/* The container */
.cp-checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
    background: #ffffff !important;
    border: 1px solid #6E35AE;
    border-radius: 9px !important;
    padding: 1px !important;
}
.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
    background: #6E35AE !important;
    border-radius: 9px !important;
}
.customScrollbarsHolder {
    height: initial !important;
    padding-bottom: 8px !important;
}
.customScrollbarsHolder .ScrollbarsCustom-Wrapper,
.customScrollbarsHolder .ScrollbarsCustom-Wrapper .ScrollbarsCustom-Scroller {
    position: initial !important;
}
.customScrollbarsTrackX {
    background: transparent !important;
    border: 1px solid #6E35AE !important;
    height: 18px !important;
    border-radius: 9px !important;
    margin-bottom: 3px !important;
    left: 3px !important;
    right: 3px !important;
    width: initial !important;
}
.customScrollbarsThUmBX {
    position: relative !important;
    background: #ffffff !important;
    border-radius: 9px !important;
    border: 1px solid #ffffff !important;
}
.customScrollbarsThUmBX:before {
    content: " " !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 2px !important;
    bottom: 0 !important;
    background: #6E35AE !important;
    border-radius: 9px !important;
}
.cp-pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.cp-pagination-info {
    margin-right: 10px;
}
.cp-pagination {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cp-pagination li {
    margin: 3px;
}
.cp-pagination li a {
    color: #626065;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
}
.cp-pagination li a:focus {
    outline: none;
}
.cp-pagination li.active a {
    color: #ffffff;
    background: #FF4715;
}
.cp-pagination li a:hover {
    color: #ffffff;
    background: #FF4715;
    text-decoration: none;
}
.cp-pagination li.disabled a:hover {
    color: #ffffff;
    background: #dddddd;
    cursor: initial;
}

@media(max-width: 1000px) {
    .rl-table-wrapper {
        margin-top: 0;
    }
    .rl-tabs-container {
        align-items: flex-end;
        margin: 0;
    }
    .rl-tabs {
        display: block;
    }
    .rl-tabs .rl-tab-item {
        margin-left: 0;
        margin-bottom: 10px;
        border-radius: 10px;
        padding: 5px 20px;
    }
    .rl-tabs-btns {
        text-align: right;
    }
    .rl-tabs-btns .tab-btn {
        margin: 10px 5px;
    }
}
@media(max-width: 700px) {
    .rl-bottom-panel {
        flex-direction: column;
        align-items: flex-end;
    }
    .rl-bottom-panel .rl-bottom-btn {
        margin-top: 10px;
        order: 1;
    }
    .rl-tabs-btns {
        flex-direction: column;
    }
}
@media(max-width: 400px) {
    .requests-list-section {
        padding: 30px 15px 50px;
    }
    .rl-tabs .rl-tab-item {
        padding: 5px 10px;
        width: 100px;
    }
}