/* footer */
footer * {
	font-size: 16px;
	line-height: 28px;
}
footer ul {
	padding: 0;
	list-style: none;
}
footer p {
	margin: 0;
}
footer .contact {
	order: 1;
}
footer .nav {
	order: 2;
}
footer .social {
	order: 3;
}
footer .map {
	order: 4;
}

footer a {
	text-decoration: none;
}
footer a:hover,
footer a:focus {
	color: #3D2170;
}
footer .contact .details {
	margin-bottom: 32px;
}
footer .social .footer-social a {
	display: flex;
	align-items: center;
}
footer .social .footer-social a svg {
	font-size: 25px;
	width: 30px;
	margin-right: 15px;
}

@media (max-width: 991px) {
	footer .contact {
		order: 3;
		text-align: center;
	}
	footer .contact .details {
		display: none;
	}

	footer .nav {
		order: 2;
		text-align: center;
	}
	footer .nav .footer-nav {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
	}
	footer .nav .footer-nav li {
		margin: 0 5px;
	}

	footer .social {
		order: 1;
	}
	footer .social .footer-social {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 5px;
	}
	footer .social .footer-social a svg {
		margin: 0 5px;
	}
	footer .social .footer-social li:nth-last-child(1) a svg {
		width: 20px;
	}
	footer .social .footer-social a span {
		display: none;
	}

	footer .map {
		display: none;
	}
}
/* footer end*/