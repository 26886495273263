.request-quote-section {
    padding-top: 100px;
    padding-bottom: 60px;
}
.request-quote-section .request-quote-form {
    margin-bottom: 50px;
}
.request-quote-section .cp-btn,
.request-quote-section .cp-btn-link {
    margin-bottom: 20px;
}
.request-quote-btns-container .cp-btn {
    min-width: 230px;
}
.request-quote-section .request-quote-image-container {
    margin-top: 110px;
}

@media (max-width: 767px) {
    .request-quote-section .request-quote-image-container {
        display: none;
    }
}