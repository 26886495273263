.login-section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-form-wrapper {
    max-width: 400px;
    width: 400px;
    padding: 20px;
    box-sizing: border-box;
}
.login-logo-container {
    display: block;
    width: 100px;
    margin: 0 auto 35px;
    text-align: center;
    text-decoration: none;
}
.login-logo-container:hover,
.login-logo-container:focus {
    text-decoration: none;
}
.login-input-wrapper {
    min-height: 90px;
    margin-bottom: 10px;
}
.login-input-wrapper label {
    font-weight: 500;
    font-size: 14px;
    color: #3d2170;
    margin: 0;
}
.login-input-wrapper .cp-input-error {
    margin: 0;
}
.login-input-wrapper input {
    border: 2px solid #3d2170;
    border-radius: 7px;
}
.login-input-wrapper input:focus {
    color: #3d2170;
    border-color: #3d2170;
    outline: 0;
    box-shadow: none;
}
.login-input-wrapper input.error {
    border: 2px solid #FF2B08;
}
.login-form-wrapper button {
    margin: 0 auto;
    display: block;
    width: 120px;
}