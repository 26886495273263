/* header navigation */
.navbar.cp-nav {
    background: #0F1341;
}
.navbar.cp-nav .navbar-brand {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.navbar.cp-nav .navbar-brand img {
    margin-right: 10px;
}
.navbar.cp-nav .navbar-nav .nav-link {
    color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
}
.navbar.cp-nav .navbar-nav .nav-link.contact-us {
    background: #FF4612;
    border-radius: 25px;
}
.navbar-toggler  {
    padding: 0;
    width: 35px;
}
.navbar-toggler span {
    display: block;
    height: 4px;
    border-radius: 2px;
    background: #ffffff;
    margin-bottom: 7px;
}
.navbar-toggler span:nth-last-child(1) {
    margin-bottom: 0;
    margin-left: 40%;
}
.navbar-toggler:focus {
    outline:none;
    box-shadow: none;
}
@media (max-width: 991px) {
    .navbar-collapse {
        position: absolute;
        z-index: 100;
        top: 54px;
        right: 0;
        width: 240px;
        background: #0F1341;
        padding: 10px 10px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .navbar.cp-nav .navbar-nav .nav-link.contact-us {
        background: transparent;
    }
}
/* header navigation end */