.request-demo-section {
    padding-top: 100px;
    padding-bottom: 60px;
}
.request-demo-section .request-demo-form {
    margin-bottom: 50px;
}
.request-demo-section .cp-btn,
.request-demo-section .cp-btn-link {
    margin-bottom: 20px;
}
.request-demo-btns-container .cp-btn {
    min-width: 230px;
}
.request-demo-section .request-demo-image-container {
    margin-top: 110px;
}

.date-picker {
    position: relative;
    margin-bottom: 5px;
}
.date-picker-arrow {
    position: absolute;
    top: 5px;
    right: 0;
    width: 12px;
    height: 25px;
    cursor: pointer;
}
.date-picker-arrow:before,
.date-picker-arrow:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 4px;
    width: 7px;
    height: 2px;
    background: #3D2170;
}
.date-picker-arrow:before {
    right: 4px;
    transform: rotate(45deg);
}
.date-picker-arrow:after {
    right: 0;
    transform: rotate(-45deg);
}
.date-picker .date-picker-error {
    display: block;
    color: #FF2B08;
    margin-top: -24px;
}

.date-picker .react-datepicker-wrapper {
    width: 100%;
    margin-bottom: 30px;
}
.date-picker .react-datepicker-wrapper .react-datepicker__input-container {
    border-radius: 0;
    border-bottom: 2px solid #3D2170;
    margin-top: 5px;
}
.date-picker .react-datepicker-wrapper input {
    padding: 4px 4px 3px 0;
    color: #3D2170;
    border: none;
    border-radius: 0;
    width: 100%;
    background: #f7f7f7;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 20px !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
    background-color: #FF2B08 !important;
    border-radius: 20px !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected:hover {
    background-color: #FF2B08 !important;
    border-radius: 20px !important;
    opacity: 0.8;
}
.react-datepicker__day--keyboard-selected:focus,
.react-datepicker__day--selected:focus {
    outline: none;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today,
.react-datepicker__day--today:hover,
.react-datepicker__month-text--today:hover,
.react-datepicker__quarter-text--today:hover,
.react-datepicker__year-text--today:hover {
    border-radius: 20px !important;
    background: #f0f0f0;
}
.date-picker input::-webkit-input-placeholder {
    color: #8572A5;
}
.date-picker input:-moz-placeholder { /* Firefox 18- */
    color: #8572A5;
}
.date-picker input::-moz-placeholder {  /* Firefox 19+ */
    color: #8572A5;
}
.date-picker input:-ms-input-placeholder {
    color: #8572A5;
}
.date-picker input::placeholder {
    color: #8572A5;
}

@media (max-width: 767px) {
    .request-demo-section .request-demo-image-container {
        display: none;
    }
}