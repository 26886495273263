.support-section {
    padding-top: 100px;
    padding-bottom: 60px;
}
.support-section .support-form {
    margin-bottom: 50px;
}
.support-section h1 {
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 30px;
}
.support-section .cp-btn,
.support-section .cp-btn-link {
    margin-bottom: 20px;
}
.support-section .support-image-container {
    margin-top: 150px;
}

@media (max-width: 1199px) {
    .support-section h1 {
        font-size: 32px;
    }
}
@media (max-width: 991px) {
    .support-section h1 {
        font-size: 27px;
    }
}
@media (max-width: 767px) {
    .support-section .support-image-container {
        display: none;
    }
}