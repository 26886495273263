body {
    font-family: 'Baloo 2', cursive;
    font-weight: 400;
    background-color: #f7f7f7;
}
body,
#root {
    height: 100vh;
}
button:focus, button:active,
input:focus, input:active,
textarea:focus, textarea:active,
select:focus, select:active {
    outline: none;
    background: transparent !important;
}
textarea {
    min-height: 88px;
}
.page-wrapper {
    min-height: calc(100vh - 260px);
}
img {
    max-width: 100%;
    max-height: 100%;
}
.bold {
    font-weight: 600;
}
.purple {
    color: #3D2170;
}
.purple-light {
    color: #6E35AE;
}
.orange {
    color: #FF2B08;
}
.blue {
    color: #049ADA;
}
.text-center {
    text-align: center;
}
.form-control {
    background: transparent;
}
.cp-btn-link {
    font-size: 1rem;
    font-weight: 300;
    line-height: 23px;
    color: #FF4612;
    background: transparent;
    border: 2px solid #FF4612;
    border-radius: 30px;
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    min-width: 170px;
    text-align: center;
    padding: .5rem 1rem;
    padding-left: 25px;
    padding-right: 25px;
}
.cp-btn-link:hover,
.cp-btn-link:focus,
.cp-btn-link.active {
    background: #FF4612 !important;
    color: #ffffff;
    text-decoration: none;
}

.slick-prev {
    top: 41%;
    left: 0;
    z-index: 100;
}
.slick-next {
    top: 41%;
    right: 0;
    z-index: 100;
}
.slick-prev:before, 
.slick-next:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 15px;
    background: #3D2170;
    transform: rotate(45deg);
    opacity: 1;
}
.slick-prev:after,
.slick-next:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 15px;
    background: #3D2170;
    transform: rotate(-45deg);
}
.slick-prev:before {
    top: -2px;
    left: 8px;
}
.slick-prev:after {
    top: 7px;
    left: 8px;
}
.slick-next:before {
    top: 7px;
    left: 8px;
}
.slick-next:after {
    top: -2px;
    left: 8px;
}

@media (max-width: 991px) {
    .navbar .container {
        max-width: 100%;
    }
}

/* Toastify */
.Toastify__toast {
    min-height: 40px;
    margin-bottom: 0;
    padding: 8px 15px;
    border-radius: 7px;
}
.Toastify__toast-container--bottom-right {
    width: 600px;
    max-width: 80%;
}
.Toastify__close-button {
    align-self: center;
    line-height: 0;
}
/* Toastify end */